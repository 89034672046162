import React from "react"
import IframeResizer from "iframe-resizer-react"
// import styles from "./comment_box.module.scss"
import { Link } from "gatsby"

function CommentBox(props) {
  const owner_key = props.ownerKey
  const thread_uri = props.uri
  const thread_title = props.title

  const embed_url = `https://my.remarkbox.com/embed?rb_owner_key=${owner_key}&thread_title=${encodeURI(thread_title)}&thread_uri=${encodeURIComponent(thread_uri)}`

  return (
    <section id="remarkbox-div" className="{styles.comments} bg-[#fffdda] dark:bg-[#ca9d00] p-4 rounded dark:text-black dark:ring-offset-[#ca9d00]">
      <h2 className="font-bold">Comments</h2>
      <p>Check the <Link class="ring-offset-[#ffdda] dark:text-blue-800 dark:ring-offset-[#ca9d00]" to="https://www.remarkbox.com/privacy-policy.html">Remarkbox privacy policy</Link> before you comment. The following information is collected by Remarkbox as a commenter:</p>
      <ul>
        <li>Email address: verify and activate your account by sending you a 1-time-password link. You can opt-in to email notifications on new comments.</li>
        <li>IP address: for Remarkbox’s audit and monitoring systems, to protect and throttle service against abuse.</li>
      </ul>
      <IframeResizer
        inPageLinks="true"
        checkOrigin="false"
        src={embed_url}
        className="border-none mb-0 max-w-none w-full"
        />
    </section>
  )
}

export default CommentBox
