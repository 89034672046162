import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import styles from "../components/footer.module.scss"
import CommentBox from "../components/comment_box"

const BlogPost = ({data, pageContext}) => {
  const post = data.markdownRemark
  const post_uri = `https://mnguyen.io${post.fields.slug}`
  return (
    <Layout>
      <Seo title={post.frontmatter.title} description={post.excerpt} />
      <article className="h-entry prose">
        <h1 style={{marginBottom: `0.5rem`}} className="p-name">{post.frontmatter.title}</h1>
        { post.frontmatter.draft ? (
          `Draft`
        ) : (
          <time style={{display: `block`, fontStyle: `italic`, margin: `0 0 1rem`}} className="dt-published" dateTime={post.frontmatter.htmldate}>{post.frontmatter.date}</time>
        )}
        <section className="e-content" dangerouslySetInnerHTML={{ __html: post.html }} />
      </article>
      <footer className="{styles.footer} mt-12 sm:grid sm:auto-grid-auto sm:grid-flow-col sm:grid-cols-[1fr auto]">
        <section>
          <h2 className="text-xl font-bold mb-2">Continue reading</h2>
          <table>
            <tbody>
            <tr className="m-0">
              <th className="font-normal border-0 p-1">Next:</th>
              <td className="border-0 p-1">{!pageContext.next && (`No newer article found.`)} {pageContext.next && (<Link to={pageContext.next.fields.slug}>{pageContext.next.frontmatter.title}</Link>)}</td>
            </tr>
            <tr className="m-0">
              <th className="font-normal border-0 p-1">Previous:</th>
              <td className="border-0 p-1">{!pageContext.previous && (`No older article found.`)} {pageContext.previous && (<Link to={pageContext.previous.fields.slug}>{pageContext.previous.frontmatter.title}</Link>)}</td>
            </tr>
            </tbody>
          </table>
        </section>
        <section>
          <h2 className="text-xl font-bold mb-2">Keep up to date</h2>
          <ul className="{styles.list} list-none m-0">
            <li className="block m-0 p-1 pl-0"><Link to="/rss.xml">Subscribe to the RSS feed</Link></li>
          </ul>
        </section>
      </footer>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(published: { eq: true }, fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt
      frontmatter {
        title
        htmldate: date
        date(formatString: "D MMMM YYYY")
        draft: draft
      }
      fields {
        slug
      }
    }
  }
`

export default BlogPost;
