/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Background from "./background"
// import styles from './layout.module.scss'

const Layout = ({ children }) => {
  /* eslint-disable jsx-a11y/anchor-is-valid */
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
      allMarkdownRemark(sort: { fields: [frontmatter___date], order: ASC }) {
        edges {
          node {
            frontmatter {
              year: date(formatString: "YYYY")
            }
          }
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <Background />
      <div className="container w-screen
      max-w-[37.5rem] flex-auto mx-auto
      2sm:-left-14 2sm:relative
      3xl:max-w-[50rem]
      pt-0 px-[1.0875rem] pb-[1.45rem] w-full">

        <main id="content" className="mt-8 font-serif max-w-none">{children}</main>
      </div>
      <footer className="max-w-[37.5rem] w-full flex-initial mx-auto
      2sm:-left-14 2sm:relative
      3xl:max-w-[50rem]

      border-t border-solid border-gray-300 dark:border-gray-700">
        <p className="px-[1.0875rem] font-serif py-4">
          © {data.site.siteMetadata.author} {data.allMarkdownRemark.edges[0].node.frontmatter.year}, {new Date().getFullYear()}. Built with
          {` `}
          <a href="https://www.gatsbyjs.org">Gatsby</a>.
        </p>
        <p><a href="#" className="goto-top px-[1.0875rem] inline-block
        print:hidden font-sans">Go to top</a></p>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
