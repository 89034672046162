import React from "react"

const Background = () => (
  <div className="fixed
  top-[12.5rem] left-8 w-[150px] h-[150px] overflow-hidden pointer-events-none
  bg-no-repeat bg-scroll bg-transparent bg-cross-pattern
  transform-gpu rotate-[30deg]">
  </div>
)

export default Background
