import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import styles from './layout.module.scss'

const Header = ({ siteTitle }) => (
  <>
  <header className="bg-blue-900 bg-header-pattern dark:bg-dark-header-pattern flex-initial z-10
  print:bg-white">
    <div className="container
      max-w-[37.5rem] flex-auto mx-auto
      2sm:-left-14 2sm:relative
      3xl:max-w-[50rem]
      px-[1.0875rem] py-[1.45rem] w-full">
      <Link
        to="/"
        className="inline-block print:text-current ring-offset-blue-900 ring-white no-underline"
      >
        <div
          className="{styles.title} text-[2rem] font-semibold text-white"
        >
          {siteTitle}
        </div>
      <div
        className="{styles.subhead} text-gray-300 print:text-gray-900 font-serif"
      >
        Sydney Full Stack Web Developer
      </div>
      </Link>
    </div>
  </header>
  <nav className="{styles.nav} transition motion-reduce:transition-none bg-gray-50 dark:bg-gray-900 sticky top-0 z-10 border-b border-solid border-gray-300 dark:border-gray-700 print:hidden" id={`nav`}>
    <ul className="{styles.menu} container
      max-w-[37.5rem] flex flex-row flex-nowrap flex-auto items-center content-evenly mx-auto
      2sm:-left-14 2sm:relative
      3xl:max-w-[51rem]
      px-4 w-full font-serif list-none justify-start">
      <li className="{styles.item} my-2 block"><Link className="nav-link"  activeClassName="nav-link--active" to="/">Home</Link></li>
      <li className="{styles.item} my-2 ml-1 sm:ml-4 block"><Link className="nav-link" activeClassName="nav-link--active" partiallyActive={true} to="/projects/" >Projects</Link></li>
      <li className="{styles.item} my-2 ml-1 sm:ml-4 block"><Link className="nav-link" activeClassName="nav-link--active" partiallyActive={true} to="/blog/">Blog</Link></li>
      <li className="{styles.item} my-2 ml-1 sm:ml-4 block"><Link className="nav-link" activeClassName="nav-link--active" to="/rss.xml">RSS</Link></li>
      <li className="{styles.item} my-2 ml-auto block"><button className="transition inline motion-reduce:transition-none px-2 py-0 focus:outline-none focus:ring ring-blue-600 ring-offset-0 ring-offset-gray-50 dark:ring-offset-gray-700 border-b-4 border-transparent" onClick={toggleDark}><span className="hidden sm:inline-block">Toggle</span> Light</button></li>
    </ul>
  </nav>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

function toggleDark() {
  switch (localStorage.theme) {
    case 'light':
      document.documentElement.classList.add('dark');
      localStorage.theme = 'dark';
      break;
    case 'dark':
      document.documentElement.classList.remove('dark');
      localStorage.theme = 'light';
      break;
    default:
      document.documentElement.classList.remove('dark');
      localStorage.theme = 'light';
      break;
  }
}

export default Header
